  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus {
    background-color: rgb(218, 159, 126) !important;
    color: #fff !important;
    /* Weitere Stileigenschaften anpassen, falls erforderlich */
  }

  input:focus {
    outline-color: orange;
  }

  .modal-footer {
    display: flex;
    padding-left: 0;
    padding-right: 0;
    justify-content: space-between;
    margin: 0rem 1rem 0rem 1rem;
  }

  .LoginFooter {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  .leftSection {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    margin: 0rem 1rem 0rem 1rem;
    padding: 0;
    text-align: left;
  }

  .leftSection p {
    margin: .25rem 0rem 0rem 0rem;
  }

  .rightSection {
    display: flex;
    justify-content: flex-end;
    margin: 1rem;
  }