h1 {
    margin-left: 1%;
    display: inline;
    font-size: 3rem;
}

button {
    margin: 0;
    padding: 0;
    display: inline;
    color: #2c29c9;
}

.comment-box {
    background-color: #f5f5f5;
    padding: 15px;
    margin-bottom: 10px;
    width: 100%;
    block-size: 100%;
    overflow: visible;
    border-radius: 10px;
}

#postbtn {
    display: inline;
}

#button {
    display: flex;
    margin: 0;
    padding: 0;
}