.signUp{
  background-color: transparent;
  color: rgb(0 0 0);
  border: none;
  border-radius: 15px;
  text-decoration: underline;
  cursor: pointer;
}

.signUp:hover{
    color: rgb(233, 124, 35);
}

.signUpSubmit{
    display: flex;
    justify-content: flex-end;
    margin: 1rem;
}

.password-requirements {
  font-size: 12px;
  color: #999;
  }