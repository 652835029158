.page-content {
  position: relative;
  color: white;
}

.menu {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  z-index: 1;
  width: 100%;
  height: 60px;
  background: white;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.35);
}

#loginbtn {
  display: flex;
  justify-content: flex-end;
  margin-right: 15px;
  margin-left: .5rem;
  margin-right: .5rem;
}

.logout-button {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end;
  margin-left: .25rem;
  margin-right: .75rem;
  background-color: rgb(163, 158, 154);
  color: rgb(255 255 255);
  border: none;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.35);
  border-radius: 15px;
  min-width: 50px;
  height: 36px;
}

.logoHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0.5rem;
}

.logoHeader img {
  max-width: 27px;
  position: relative;
  top: 1px;
  left: .25rem;
}

@media screen and (max-width: 768px) {
  .headline {
    display: none;
  }
}

#placesSwitchBtn {
  display: flex;
  margin-left: .5rem;
  margin-right: .5rem;
}

.myPlacesAndLogout {
  display: flex;
}

.radius {
  width: auto;
  margin-right: .25rem;
  margin-left: .25rem;
  background-color: rgb(212 73 19);
  color: rgb(255 255 255);
  border: none;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.35);
  border-radius: 15px;
  height: 36px;
  position: fixed;
  top: 113px;
  left: 3.5px;
}

.radius img {
  max-width: 26px;
  position: relative;
  top: -1.5px;
  left: -2px;
}

.radius:hover {
  background-color: rgb(233, 124, 35);
}

#addPlace {
  display: flex;
  justify-content: flex-end;
  background: transparent;
  position: fixed;
  z-index: 9999;
}

img {
  max-width: 100%;
}

h1 {
  display: flex;
  align-items: center;
  margin: 0.5rem;
  font-weight: bold;
  font-size: 48px;
  color: rgb(212 73 19);
  margin-left: 10px;
}

h4 {
  display: flex;
}

.buttons {
  position: absolute;
  top: 70px;
  right: 10px;
  z-index: 1;
  /* Über den Kartenlayer platzieren */
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  font-weight: bold;
}

.custom-button {
  background-color: rgb(212 73 19);
  color: rgb(255 255 255);
  border: none;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.35);
  border-radius: 15px;
}

.custom-button:hover {
  background-color: rgb(233, 124, 35);
}

.custom-button2 {
  background-color: rgb(163, 158, 154);
  color: rgb(255 255 255);
  border: none;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.35);
  border-radius: 15px;
}

.leaflet-container {
  z-index: 0;
}

#hello {
  color: rgb(212 73 19);
}

.tags-input-container {
  border: 1.5px solid rgb(121, 117, 113);
  padding: 0.5em;
  border-radius: 15px;
  width: min(30vw, 600px);
  width: max(50vw);
  height: 3rem;
  margin-top: .5em;
  display: flex;
  flex-flow: column wrap;
  align-items: flex-start;
  justify-content: center;
  top: -0.25em;
  position: relative;
  margin-left: .5rem;
  margin-right: .25rem;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);

}

.tags-input {
  width: min(25vw, 560px);
  width: max(46vw);
}

.tags-input-container-create {
  overflow-x: auto;
  border: 1px solid #ced4da;
  overflow-y: hidden;
  width: 100%;
  padding: 0.375rem 0.75rem;
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  border-radius: 0.375rem;
  min-height: 3rem;
}

.tags-input-create {
  flex-grow: 1;
  padding: .25em 0;
  border: none;
  outline: none;
  margin-top: .4rem;
}

.tags-view {
  width: 100vw;
  margin-top: 7.2rem;
}


.tag-item {
  background-color: rgb(255, 255, 255);
  opacity: .95;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.35);
  color: #504e4e;
  padding: .25em .5em;
  border-radius: 20px;
  margin-right: 0.25rem;
  height: 36px;

}

.tag-item-create {
  display: inline-block;
  padding: .25em .5em;
  margin-right: 0.4em;
  border-radius: 20px;
  margin-bottom: .4rem;
  margin-top: .4rem;
  background-color: rgb(255, 255, 255);
  opacity: .95;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.35);
  border-radius: 20px;
  height: 32px;
}

.tag-item-create-close {
  height: 20px;
  width: 20px;
  background-color: rgb(235, 235, 235);
  color: rgb(100, 96, 96);
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-left: .5em;
  font-size: 18px;
  cursor: pointer;
}

.tag-item-create .close {
  height: 20px;
  width: 20px;
  background-color: rgb(56, 55, 55);
  color: white;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-left: .5em;
  font-size: 18px;
  cursor: pointer;
}

.tag-item .text {
  margin-left: .25rem;
}

.tag-item .close {
  height: 20px;
  width: 20px;
  background-color: rgb(235, 235, 235);
  color: rgb(100, 96, 96);
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-left: .5em;
  font-size: 18px;
  cursor: pointer;
}

.tags-input {
  position: fixed;
  flex-grow: 1;
  padding: .25em 0;
  border: none;
  outline: none;
}

.comments-container {
  width: 100%;
}

.comments-content {
  overflow-y: auto;
  max-height: 230px;
  width: 100%;
  padding: 5px;
  margin-bottom: 1em;
  border-radius: 10px;
}

.comment-user {
  font-weight: bold;
}

.comment-timestamp {
  color: blue;
}

p.comment-text {
  color: #000000;
  font-size: 15px;
  word-break: break-word;
  min-width: 275px;
  border-radius: 25px;
  margin: 0rem;
}

.Löschen-Button {
  font-size: 12px;
  padding: 6px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 7px;
  background-color: #bb1b1b;
  color: #fff;
}

.comment-input-container {
  align-items: center;
  margin-bottom: 25px;

}

.comment-input {
  flex-grow: 1;
  resize: vertical;
  min-height: 40px;
  max-height: 120px;
  max-width: 200px;
  padding: 4px;
  margin-right: 4px;
  margin-bottom: 8px;
}

.Popup-Place {
  margin-left: 2.5%;
  width: 95%;
}

.comment-buttons-container {
  display: flex;
  width: 100%;
  flex: 1;
  margin-top: 0;
}

.comment-buttons-container button {
  flex: 1;
}

.image-preview {
  width: 70%;
  margin-top: 10px;
}

.dropdown-menu {
  margin: 5px;
  margin-left: 0px;
}