#button img {
    height: 18px;
}

#postbtn {
    /* Hinzufügen Button  */
    position: fixed;
    top: 68px;
    left: 7px;
}

#createPlaceFooter {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 1rem;
}

#position {
    margin: .25rem;
}

#selectPosition {
    margin: .25rem;
}

#submit {
    margin: .25rem;
    margin-right: 0;
}

.modal-header {
    background-color: rgb(212 73 19);
    color: #fff;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
}

.buttonsRight {
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    margin: 0;
    padding: 0;

}

.buttonsLeft {
    display: flex;
    justify-content: flex-start;
    margin: 0;
    padding: 0;
}

.feedback-window {
    position: fixed;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    color: #000;
    padding: 16px;
    border-radius: 8px;
    z-index: 9999;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.feedback-message {
    font-size: 16px;
    font-weight: bold;
    text-align: center;
}

.feedback-info {
    font-size: 14px;
    text-align: center;
}