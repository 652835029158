:root {
  --minWidth: 400px;
  --maxWidth: 500px;
  --height: auto;
}

.leaflet-popup-tip {
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.35);
  display: none;
}

.popup-content {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-Height: 70vh;
  overflow-x: hidden;
  overflow-y: scroll;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.35);
  border-radius: 10px;
  z-index: 9999;
}

.popup-img {
  width: 100%;
  height: var(--height);
  border-radius: 10px 10px 0px 0px;
  margin-bottom: .25rem;
}

#imgPlace {
  min-width: var(--minWidth);
  max-width: var(--maxWidth);
}

.custom-popup .leaflet-popup-content {
  width: auto;
  min-width: var(--minWidth);
  max-width: var(--maxWidth);

}

#editbtn {
  margin: .5rem;
  padding: 0;
  background-color: rgb(212 73 19);
  border: none;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.35);
  color: white;
  width: 36px;
  height: 36px;
}

#deletebtn {
  background-color: rgb(163, 158, 154);
  margin: .5rem;
  padding: 0;
  border: none;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.35);
  color: white;
  width: 36px;
  height: 36px;
}

#deletebtn:hover {
  background-color: rgb(68, 77, 83);
}


#editbtn:hover,
#likebtn:hover {
  background-color: rgb(233, 124, 35);
}

.custom-popup .leaflet-popup-content {
  margin: 0;
  padding: 0;
}

.custom-popup .leaflet-popup-content-wrapper {
  background-color: white;
  box-shadow: none;
}

.placeName {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0rem .5rem 0rem .5rem;
  margin-top: 1rem;
}

#placeNameAndButtons {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.scrollable {
  max-height: 500px;
  overflow-y: scroll;
}


#buttonsPost {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-left: auto;
}

.description {
  text-align: justify;
  padding: 0rem .5rem 0rem .5rem;
  margin: 0px .5rem;
  margin-bottom: 0rem;
  position: relative;
  min-height: 80px;
  flex-shrink: 1;
  padding-right: 10px;
}

.description::-webkit-scrollbar {
  /* Breite der Scrollbar anpassen */
  width: 8px;
  /* Hintergrundfarbe der Scrollbar */
  background-color: #f1f1f1;
}

.description::-webkit-scrollbar-thumb {
  background-color: #888;
  /* Farbe des Scrollbar-Daumens */
  border-radius: 4px;
  /* Abrundung des Scrollbar-Daumens */
}

.tags {
  margin-top: .5rem;
  position: relative;
  top: -1rem;
}

.tag-item-view {
  background-color: rgb(255, 255, 255);
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.35);
  display: inline-block;
  padding: .25em .5em;
  border-radius: 20px;
  margin: .5em;
  min-height: 28px;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  font-size: 13px;
}

p.author {
  padding: 0rem .25rem 0.5rem .5rem;
  margin: 0rem;
}

#authorAndLike {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
}

#likebtn {
  margin-left: .5rem;
  margin-bottom: .5rem;

  background-color: rgb(212 73 19);
  border: 1px solid rgb(212 73 19);
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.35);
  color: white;
  height: 36px;
}

#likebtn.liked {
  background-color: rgb(166, 59, 16);
}

#likebtn img {
  margin-bottom: .2rem;
  margin-right: 0.2rem;
}

#openComments {
  background-color: rgb(212 73 19);
  border: none;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.35);
  color: white;
  margin-bottom: 0.5rem;
}

#openComments:hover {
  background-color: rgb(233, 124, 35);
}

#commentSubmit {
  background-color: rgb(212 73 19);
  border: none;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.35);
  color: white;
  height: 36px;
  margin-left: .5rem;
}

#commentSubmit:hover {
  background-color: rgb(233, 124, 35);
}

#deleteComment {
  background-color: rgb(163, 158, 154);
  margin: .5rem;
  padding: 0;
  border: none;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.35);
  color: white;
  width: 36px;
  height: 36px;
}

#deleteComment:hover {
  background-color: rgb(68, 77, 83);
}

.Kommentar-hinterlassen {
  margin-left: .5em;
  padding-left: 1.5%;
  width: 100%;
}

.popup-open {
  z-index: 9999;
  pointer-events: auto;
}

.liked {
  background-color: black;
  color: black;
}

@media screen and (max-width: 450px) {
  :root {
    --minWidth: 95vw;
    --maxWidth: 500px;
    height: 70vh;
    /* overflow: auto; */
  }
}